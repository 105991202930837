<script>
import ResourceDetail from '@shell/components/ResourceDetail';

export default {
  name:       'KontainerDriverEditPage',
  components: { ResourceDetail },
};
</script>

<template>
  <ResourceDetail resource-override="kontainerDriver" />
</template>
